import React from "react";
import styles from "./styles.module.scss";

const IconoGraphy = ({
  iconClass,
  iconColor,
  fontSize = "f_24",
  customClass = "",
  title = "",
  clickHandler,
  rotate = true
}) => {
  return (
    <i
      title={title}
      className={`iconwasalt ${iconClass} ${!rotate && styles.iconTransform} ${styles[iconColor]} ${styles[customClass]} ${styles[fontSize]}`}
      onClick={() => {
        if (clickHandler) clickHandler();
      }}
    ></i>
  );
};

export default IconoGraphy;
